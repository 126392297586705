<template>
  <section-table
      :columns="[
      'Наименование документа',
      'Номер документа',
      'Дата документа',
      'Регистрационный номер',
      'Дата регистрации',
      'Вид документа',
      'Статус действия',
    ]"

  >
    <template #rows v-if="documents.length!==0">
        <tr
            v-for="doc in documents"
            :key="doc.id"
            @click="$emit('rowClick', doc)"
            class="table__tr"
        >
          <td class="table__td" v-html="highlight(query, doc.name)"></td>
          <td class="table__td" v-html="highlight(query, doc.number)"></td>
          <td
              class="table__td"
              v-html="highlight(query, $filters.toDateString(doc.date))"
          ></td>
          <td
              class="table__td"
              v-html="highlight(query, doc.registrationNumber)"
          ></td>
          <td
              class="table__td"
              v-html="highlight(query, $filters.toDateString(doc.registrationDate))"
          ></td>
          <td class="table__td" v-html="highlight(query, doc.documentType)"></td>
          <td class="table__td" v-html="highlight(query, doc.status)"></td>
        </tr>
    </template>
    <template #empty v-if="documents.length===0">
      <tr>
        <td colspan="7">
          <TableNoData/>
        </td>
      </tr>
    </template>
  </section-table>
</template>

<script>
import SectionTable from "../generic/sectionTable";
import useTextHighlight from "../../hooks/textHighlight";
import TableNoData from "@/components/gisogdDocuments/TableNoData.vue";

export default {
  components: {TableNoData, SectionTable},
  props: {
    documents: Array,
    query: String,
  },
  emits: ["rowClick"],
  setup() {
    const {highlight} = useTextHighlight();
    return {highlight};
  },
};
</script>
