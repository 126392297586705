<template>
  <div class="page">
    <page-header>
      <template #title> {{ state.pageName }}</template>
      <!-- <template #subtitle>
        <p class="">
          Последнее обновление 20 минут назад
        </p>
      </template> -->
      <template #buttons>
        <button
            v-if="Number(sectionNumber) === 17 && showOpenMapButton"
            class="button button--primary"
            @click="onClickOpenMap"
        >Открыть карту
        </button>
      </template>
    </page-header>
    <div class="box">
      <FetchJson :url="state.documentsUrl">
        <template #default="{ json: documentsData, loading: isLoading }">
          <div class="container flex-row table-search-fields">
            <UniversalSelectField
                v-if="currentProject.isRegion"
                :modelValue="selectedMo"
                @update:modelValue="updateSelectedMo($event)"
                @firstRowClick="updateSelectedMo(null)"
                :displayProp="'name'"
                :keyProp="'projectAlias'"
                @filter="fetchWithDelay($event)"
                :placeholderText="'Все территории'"
                :isFirstRowFull="true"
                :options="moList"
                class="table-search-field mo-filter-field"
            >
              <template #firstRow>Все территории</template>
            </UniversalSelectField>
            <SearchField
                v-model="state.documentsState.search"
                class="table-search-field"
                :key="sectionNumber"
            ></SearchField>
          </div>
          <div class="documents-page__table-container">
            <TabSpinner v-if="isLoading"/>
            <Documents13Table
                v-else-if="sectionNumber === 13"
                :documents="documentsData.items"
                :query="state.documentsState.search.trim()"
                @rowClick="state.onRowClick($event.registrationNumber)"
            ></Documents13Table>
            <DocumentsTable
                v-else
                :documents="documentsData.items"
                :query="state.documentsState.search.trim()"
                @rowClick="state.onRowClick($event.registrationNumber)"
            ></DocumentsTable>
            <Pagination
                v-if="documentsData"
                v-model:currentPage="state.documentsState.page"
                :totalPages="documentsData.totalPages"
            />
          </div>
        </template>
      </FetchJson>

      <footer class="table-section__footer"></footer>
    </div>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import useGisogdDocuments from "./hooks/gisogdDocumentsPage";
import DocumentsTable from "./DocumentsTable.vue";
import Documents13Table from "./Documents13Table.vue";
import TabSpinner from "../basic/tabs/TabSpinner";
import Pagination from "../basic/Pagination";
import SearchField from "../basic/SearchField";
import {ref, onBeforeUpdate, onMounted} from "vue";
import useProjectAlias from "../../hooks/projectAlias";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";
import axios from "axios";

export default {
  components: {
    UniversalSelectField,
    TabSpinner,
    Pagination,
    SearchField,
    DocumentsTable,
    Documents13Table
  },
  setup() {
    const route = useRoute();
    const {projects, currentProject} = useProjectAlias();
    const moList = projects.value.filter(x => !x.isRegion);

    const sectionNumber = ref(route.params.sectionNumber);

    const selectedMo = ref(false);
    const showOpenMapButton = ref(false);
    const moProjectAliasFilter = ref(null);

    const url =
        (process.env.NODE_ENV === 'production'
            ? window.location.origin
            : 'http://localhost:5173') + '/information-models/'+ currentProject.value.projectAlias;

    async function checkModuleAvailability() {
      try {
        await axios.get(url);
        showOpenMapButton.value = true;
      } catch (e) {
        console.log(e)
        showOpenMapButton.value = false;
      }
    }

    onMounted(checkModuleAvailability);

    function showMap() {
      window.open(url);
    }

    function updateSelectedMo(value) {
      if (value && value.projectAlias !== 'all') {
        moProjectAliasFilter.value = value.projectAlias;
        selectedMo.value = value;
      } else {
        moProjectAliasFilter.value = null;
        selectedMo.value = false;
      }
    }

    const state = ref(useGisogdDocuments(sectionNumber.value, moProjectAliasFilter));

    onBeforeUpdate(() => {
      if (sectionNumber.value === route.params.sectionNumber) {
        return;
      } else {
        sectionNumber.value = route.params.sectionNumber;
      }
      if (sectionNumber.value)
        state.value = useGisogdDocuments(sectionNumber.value, moProjectAliasFilter);
    });

    return {
      state,
      sectionNumber,
      selectedMo,
      updateSelectedMo,
      moList,
      currentProject,
      onClickOpenMap: showMap,
      showOpenMapButton
    };
  },
};
</script>
<style lang="scss">
@media screen and (max-height: 1024px) {
  .documents-page__table-container {
    height: 50vh !important;
  }
}

@media screen and (max-height: 830px) {
  .documents-page__table-container {
    height: 44vh !important;
  }
}

@media screen and (max-height: 770px) {
  .documents-page__table-container {
    height: 41vh !important;
  }
}

.documents-page__table-container {
  height: 60vh;
}

.table-search-fields {
  margin-left: 0;
}

.table-search-fields > .table-search-field:not(last-child) {
  margin-right: 20px;
}

.table-search-fields > .table-search-field > .form-control {
  margin-top: 0;
}

.mo-filter-field {
  max-width: 450px;
  margin-top: -6px;
}
</style>
