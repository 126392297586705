import {useRouter} from 'vue-router';
import {watch} from 'vue';
import useSectionRoutes from '../../../hooks/sectionRoutes';
import useSections from '../../../hooks/sections';
import useSearch from '../../../hooks/useSearch';

export default function useGisogdDocuments(sectionNumber, moProjectAlias) {
	const {getSection} = useSections();
	const {docRoutes} = useSectionRoutes();
	const section = getSection(sectionNumber);
	const route = docRoutes.find((r) => r.number === +sectionNumber);

	// const moProjectAliasesRef = ref(moProjectAliases);


	const {state: documentsState, url: documentsUrl} = {

		...useSearch(`section${sectionNumber}/documents`,
			1, 20, '',
			moProjectAlias && moProjectAlias.value
				? {moProjectAlias: [moProjectAlias.value]}
				: null
		),
	};

	watch(() => moProjectAlias.value, (value) => {
		const temp = {...documentsState.additionalParameters};
		if (!value)
			delete temp.moProjectAlias;
		else
			temp.moProjectAlias = [value];
		documentsState.additionalParameters = temp;
	})

	const router = useRouter();

	function onRowClick(key) {
		router.push({name: route.name, params: {number: key}});
	}


	return {pageName: section?.name, documentsUrl, documentsState, onRowClick};
}
