<template>
  <div class="table-container">
  <table class="table">
    <tr class="">
      <th v-for="(column, idx) in columns" :key="idx" class="table__header-text">{{column}}</th>
    </tr>
    <slot name="rows"></slot>
    <slot name="empty"></slot>
  </table>
  </div>
</template>

<script>

export default {
  props: {
    columns: Array
  }
};
</script>