<template>
  <div class="no-data__container">
    <div class="no-data__image">
    </div>
    <div class="no-data__text">Данные отсутствуют</div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.no-data__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
}

.no-data__image {
  height: 240px;
  width: 337px;
  background-image: url("../../assets/svg/table.svg");
  background-repeat: no-repeat;
}

.no-data__text {
  margin-top: 24px;
  font-size: 16px;
}
</style>